// Here you can add other styles
.add-img {
  width: 80%;
  height: 200px;
  object-fit: contain;
}

@media screen and (max-width: 780px) {
  .add-img {
    width: 80%;
    height: unset;
    object-fit: contain;
  }

  .cutom-loader {
    position: absolute;
    top: 70% !important;
    left: 30% !important;
  }
}

.c-main {
  padding-top: 1em;
}

.cutom-loader {
  position: absolute;
  top: 70%;
  left: 50%;
}

.earnings-card-header--left-side {
  display: flex;
  min-width: 230px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 500px) {
    min-width: unset;
  }

  .left-side--info-container {
    width: 160px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    align-items: center;

    @media screen and (max-width: 500px) {
      width: unset;
      font-size: 14px;
    }

    .info-container--up-vote {
      .fa-arrow-up {
        color: #05d305;
        cursor: pointer;
      }

      .percentage {
        font-size: 14px;

        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
      }

      @media screen and (max-width: 500px) {
        margin: 0 10px;
      }
    }

    .info-container--down-vote {
      .fa-arrow-down {
        color: red;
        cursor: pointer;
      }

      .percentage {
        font-size: 14px;

        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
      }

      @media screen and (max-width: 500px) {
        margin-right: 5px;
      }
    }

    .info-container--total-vote {
      font-size: 16px;

      @media screen and (max-width: 500px) {
        font-size: 12px;
      }
    }
  }
}
