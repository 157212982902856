// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

.tradingview-widget-container {
  height: calc(100vh - 190px);
}

button:focus {
  outline: none;
}

.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.MuiInputBase-input {
  font-size: 14px !important;
}

.MuiMenu-paper {
  top: 200px !important;
}
